import { defineStore } from "pinia";
import API from "../services/api";

import { useMainStore as mainStore } from "./main";
import { useUserStore as userStore } from "./user";

export const useBullStore = defineStore("bull", {
    state: () => ({
        wsStatus: false,
        ws: null,
        games: [],
        game: {},
        finish1: {},
        // game: {
        //     id: "r3i(",
        //     me: {
        //         id: 406209800,
        //         name: "Александр",
        //         picture: "u1.jpg",
        //         mode: "up",
        //         clicks: {
        //             1723471717: 1,
        //             1723471718: 5,
        //             1723471719: 2,
        //             1723471720: 6,
        //             1723471721: 6,
        //             1723471722: 6,
        //             1723471723: 6,
        //             1723471724: 2,
        //             1723471726: 4,
        //             1723471727: 6,
        //             1723471728: 7,
        //             1723471729: 6,
        //             1723471730: 6,
        //             1723471731: 6,
        //             1723471732: 6,
        //             1723471733: 8,
        //             1723471734: 5,
        //             1723471736: 2,
        //             1723471737: 7,
        //             1723471738: 8,
        //             1723471739: 9,
        //             1723471740: 7,
        //             1723471741: 6,
        //             1723471742: 7,
        //             1723471743: 8,
        //             1723471744: 7,
        //             1723471745: 7,
        //             1723471746: 1,
        //             1723471748: 4,
        //             1723471749: 7,
        //             1723471750: 5,
        //             1723471751: 3,
        //             1723471771: 5,
        //             1723471775: 2,
        //         },
        //         status: 2,
        //     },
        //     opponent: {
        //         id: 574461920,
        //         name: "Анна",
        //         picture: "u10.jpg",
        //         mode: "down",
        //         clicks: {
        //             1723471716: 2,
        //             1723471717: 1,
        //             1723471751: 1,
        //             1723471752: 5,
        //             1723471753: 7,
        //             1723471754: 7,
        //             1723471755: 2,
        //             1723471756: 4,
        //             1723471757: 7,
        //             1723471758: 7,
        //             1723471759: 7,
        //             1723471760: 7,
        //             1723471761: 7,
        //             1723471762: 8,
        //             1723471763: 7,
        //             1723471764: 7,
        //             1723471765: 8,
        //             1723471766: 3,
        //             1723471767: 2,
        //             1723471768: 1,
        //             1723471772: 7,
        //             1723471773: 6,
        //             1723471774: 6,
        //         },
        //         status: 2,
        //     },
        //     time: Math.floor(Date.now() / 1000),
        //     upSum: 183,
        //     downSum: 119,
        //     status: 7,
        // },
        leaders: {},

        finish: {
            id: "qD%m",
            players: {
                406209800: {
                    id: 406209800,
                    name: "Александр",
                    picture: "u1.jpg",
                    mode: "down",
                    clicks: {
                        1723690414: 3,
                        1723690415: 11,
                        1723690416: 6,
                        1723690428: 5,
                        1723690429: 10,
                        1723690430: 6,
                        1723690431: 2,
                        1723690432: 1,
                        1723690433: 4,
                        1723690434: 1,
                    },
                    status: 2,
                },
                574461920: {
                    id: 574461920,
                    name: "Анна",
                    picture: "u10.jpg",
                    mode: "up",
                    clicks: {
                        1723690412: 3,
                        1723690413: 6,
                        1723690414: 2,
                        1723690416: 3,
                        1723690417: 6,
                        1723690418: 6,
                        1723690419: 4,
                        1723690420: 2,
                        1723690421: 6,
                        1723690422: 7,
                        1723690423: 3,
                        1723690424: 6,
                        1723690425: 4,
                        1723690426: 3,
                        1723690427: 1,
                        1723690429: 4,
                        1723690430: 6,
                        1723690431: 1,
                        1723690432: 6,
                        1723690433: 2,
                        1723690434: 2,
                        1723690435: 2,
                        1723690471: 6,
                    },
                    status: 2,
                },
            },
            time: 1723690413,
            upSum: 91,
            downSum: 49,
            win: 574461920,
            point: 100,
            status: 7,
        },

        // буффер кликов
        clickBuffer: 0, // Буфер для накопления количества кликов
        clickTime: null, // Последнее время клика
        clickTimer: null, // Таймер для отправки кликов
    }),

    actions: {
        // Подготовка игры после получения (вместо players будет me & opponent)
        setGame(game) {
            if (!userStore().student?.fid) return;

            const me = game.players[userStore().student?.fid];
            let opponent = null;

            // Найдем оппонента
            Object.keys(game.players).forEach((playerId) => {
                if (parseInt(playerId) !== parseInt(userStore().student?.fid)) opponent = game.players[playerId];
            });

            this.game = { ...game, me, opponent };

            // Убираем players из game
            delete this.game.players;
        },

        // WebSocket
        async connectWs() {
            if (!userStore().student?.fid) return;

            // Установите WebSocket соединение
            this.ws = new WebSocket("wss://go.botteach.ru/bull/new?fid=" + userStore().student?.fid);

            this.ws.onopen = () => {
                console.log("WebSocket соединение установлено");
                this.wsStatus = true;
                this.getStudentWS();
                this.waitGames();
            };

            this.ws.onmessage = (event) => {
                const message = JSON.parse(event.data);
                console.log("Получено сообщение:", message);

                if (message.type === "Welcome") {
                    userStore().setStudent(message.student);
                    mainStore().setAlert({ msg: message.text, type: "success" });
                }

                if (message.type === "Student") {
                    userStore().updateStudent(message.student);
                }
                if (message.type === "CreatedGame") {
                    this.setGame(message.game);
                    mainStore().setPopup("b:wait");
                }

                if (message.type === "GamesList") {
                    this.games = message.games;
                    if (parseInt(this.games?.length) > 0) mainStore().setAlert({ msg: "New game waiting you", type: "success" });
                    // mainStore().setPopup("b:games");
                }

                if (message.type === "JoinedGame") {
                    if (message.game) {
                        this.setGame(message.game);
                        mainStore().setPopup("b:wait");
                    }
                }

                if (message.type === "Clicked") {
                    if (parseInt(this.game.opponent.id) === parseInt(message.fid)) {
                        if (!this.game.opponent.clicks[message.t]) this.game.opponent.clicks[message.t] = 0;
                        this.game.opponent.clicks[message.t] += message.c;

                        // Пересчитываем upSum и downSum
                        this.game.opponent.mode == "up" ? (this.game.upSum += message.c) : (this.game.downSum += message.c);
                    }
                }

                if (message.type === "FinishedGame") {
                    if (message.game) {
                        this.finish = message.game;
                        // userStore().addPoint(message.game.point);
                        this.getStudentWS();
                    }
                }

                if (message.type === "wait") {
                    console.log("wait", message.text);
                }
            };

            this.ws.onerror = (error) => {
                console.error("WebSocket ошибка:", error);
            };

            this.ws.onclose = () => {
                this.wsStatus = false;
                console.log("WebSocket соединение закрыто");
            };
        },

        getStudentWS() {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                const message = JSON.stringify({
                    type: "GetStudent",
                    bot_id: userStore().student.bot_id,
                    fid: userStore().student.fid,
                });
                this.ws.send(message);
                console.log("Отправлено сообщение:", message);
            } else {
                console.error("WebSocket соединение не установлено");
            }
        },

        createGameWS(friend) {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                const message = JSON.stringify({
                    type: "CreateGame",
                    fid: userStore().student.fid,
                    friend: friend.fid,
                });
                this.ws.send(message);
                console.log("Отправлено сообщение:", message);
            } else {
                console.error("WebSocket соединение не установлено");
            }
        },

        waitGames() {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                const message = JSON.stringify({
                    type: "WaitGames",
                    fid: userStore().student.fid,
                });
                this.ws.send(message);
                console.log("Отправлено сообщение:", message);
            } else {
                console.error("WebSocket соединение не установлено");
            }
        },

        joinGame(data) {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                const message = JSON.stringify({
                    type: "JoinGame",
                    game_id: data.game_id,
                    fid: data.fid,
                });
                this.ws.send(message);
                console.log("Отправлено сообщение:", message);
            } else {
                console.error("WebSocket соединение не установлено");
            }
        },

        // sendClicksWS(time) {
        //     if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        //         const message = JSON.stringify({
        //             type: "ClickGame",
        //             game_id: this.game.id,
        //             fid: userStore().student.fid,
        //             time: time,
        //             count: 1,
        //         });
        //         this.ws.send(message);
        //         console.log("Отправлено сообщение:", message);
        //     } else {
        //         console.error("WebSocket соединение не установлено");
        //     }
        // },

        sendClicksWS(time) {
            // Обновляем буфер и время
            this.clickBuffer += 1;
            this.clickTime = time;

            // Если таймер еще не запущен, запускаем его
            if (!this.clickTimer) {
                this.clickTimer = setTimeout(() => {
                    // Отправляем накопленные клики
                    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                        const message = JSON.stringify({
                            type: "ClickGame",
                            game_id: this.game.id,
                            fid: userStore().student.fid,
                            time: this.clickTime, // Отправляем последнее время
                            count: this.clickBuffer, // Отправляем накопленное количество кликов
                        });
                        this.ws.send(message);
                        console.log("Отправлено сообщение:", message);
                    } else {
                        console.error("WebSocket соединение не установлено");
                    }

                    // Сбрасываем буфер и таймер
                    this.clickBuffer = 0;
                    this.clickTimer = null;
                }, 250); // Накопление кликов в течение 250 миллисекунд
            }
        },

        finishGameWS() {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                // console.log("F Game (send)", this.game);

                const message = JSON.stringify({
                    type: "FinishGame",
                    game_id: this.game.id,
                    fid: userStore().student.fid,
                    clicks: this.game.me.clicks,
                });
                this.ws.send(message);
                console.log("Отправлено сообщение:", message);
            } else {
                console.error("WebSocket соединение не установлено");
            }
        },

        addClick(time, click) {
            if (!userStore()?.student?.fid) return;

            // Получаем объект игрока по его ID
            if (!this.game.me) return;
            if (!this.game.me.clicks) this.game.me.clicks = {};

            // Добавляем клики текущего игрока
            if (!this.game.me.clicks[time]) this.game.me.clicks[time] = 0;
            this.game.me.clicks[time] += click;

            // Пересчитываем upSum и downSum
            this.game.me.mode == "up" ? (this.game.upSum += click) : (this.game.downSum += click);
        },

        async getLeaders() {
            if (!userStore()?.student?.fid) return;

            const res = await API.goGET("bull/get-leaders", { fid: userStore().student.fid });
            console.log("getLeaders", res.data);
            this.leaders = res.data;
        },
    },
});
