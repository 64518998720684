import { createRouter, createWebHistory } from "vue-router";

import BullBear from "@/BullBear/Main";

const routes = [
    {
        path: "/",
        name: "BullBear",
        component: BullBear,
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to) => {
    document.title = to.meta.title || "Baraban.io";
});

export default router;
