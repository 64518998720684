import { defineStore } from "pinia";
import moment from "moment";

export const useMainStore = defineStore("main", {
    state: () => ({
        alert: {},
        s: {
            find: null,
            bot_id: null,
            group_id: null,
            user_id: null,
            type: null,
            status: null,
            month: moment().startOf("month").format("YYYY-MM-DD"),
            start: null,
            end: null,
            popup: null,
            modal: null,
            clear: null,
            view: null,
            hidden: false, // Predictor: скрыть кнопки на графике во время анимации
            answer: false,
            alarm: { time: 0, start: 0 },
            lang: "en",
        },
        popupHistory: [],
        modalQueue: [],
        minCounter: 0,
    }),

    actions: {
        setAlert(alert) {
            this.alert = alert;
        },

        loadS() {
            const s = localStorage.getItem("s");
            if (s && s !== "undefined") this.s = JSON.parse(s);
        },

        setSettings(s) {
            this.s = s;
            localStorage.setItem("s", JSON.stringify(s));
        },

        clearSettings() {
            this.s.find = null;
            this.s.group_id = null;
            this.s.user_id = null;
            this.s.type = null;
            this.s.status = null;
            this.s.month = moment().startOf("month").format("YYYY-MM-DD");
            this.s.start = null;
            this.s.end = null;
            this.s.clear = null;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setPopup(popup) {
            if (this.s.popup) this.popupHistory.push(this.s.popup);
            this.s.popup = popup;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        closePopup() {
            if (this.popupHistory.length > 0) {
                this.s.popup = this.popupHistory.pop();
            } else {
                this.s.popup = null;
            }
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setModal(modal) {
            // Если есть модальное окно, добавляем в очередь, если нет, показываем сразу
            this.s.modal ? this.modalQueue.push(modal) : (this.s.modal = modal);
        },

        closeModal() {
            this.setModal(null);
            if (this.modalQueue.length > 0) this.s.modal = this.modalQueue.shift();
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setPeriod(data) {
            this.s.month = null;
            this.s.start = data.start;
            this.s.end = data.end;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setView(view) {
            this.s.view = view;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setHidden(status) {
            this.s.hidden = status;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setLang(lang) {
            if (!lang || lang == undefined) return;

            this.s.lang = lang;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setAlarm(time) {
            this.s.alarm = { time: time, start: parseInt(new Date().getTime() / 1000) };
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setAnswer(answ) {
            this.s.answer = answ;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        // тактильная технология
        haptic() {
            if (window.Telegram.WebApp.HapticFeedback && window.Telegram.WebApp.HapticFeedback.impactOccurred) {
                window.Telegram.WebApp.HapticFeedback.impactOccurred("light");
            }
        },
    },
});
