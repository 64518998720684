import axios from "axios";
import authHeader from "./header";
class UserService {
    goGET(url, data) {
        return axios.get("https://go.bull.baraban.io/" + url, { params: data, headers: authHeader() });
    }

    goPOST(url, formData) {
        return axios.post("https://go.bull.baraban.io/" + url, formData, { headers: authHeader() });
    }
}
export default new UserService();
